import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import Select from "react-select";
import InputFiles from "react-input-files";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Notif from "../../components/Notif/Notif";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import Loading from "../../components/Notif/LoadingSpiner";
import Resizer from "react-image-file-resizer";

export default class Regis extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
    if (sessionStorage.getItem("data")) {
      let dataPasien = JSON.parse(sessionStorage.getItem("data"));
      var str = dataPasien.NORM;
      var pad = "00000000";
      var ans = pad.substring(0, pad.length - str.length) + str;
      this.state = {
        nama: dataPasien.NAMA,
        norm: ans,
        tglLahir: dataPasien.TANGGAL_LAHIR,
        jekel: dataPasien.JENIS_KELAMIN,
        jl: dataPasien.ALAMAT,
        desa: dataPasien.WILAYAH,
        RT: dataPasien.RT,
        RW: dataPasien.RW,
        preKTP: false,
        preRujukan: false,
        preKartuKontrol: false,
        redirect: false,
        loading: false,
        wa: localStorage.getItem('nohp') ? localStorage.getItem('nohp') : ''
      };
    }
  }
  listKlinik = async () => {
    await Api.post("options/listklinik", this.state).then((k) => {
      k.data.success === true
        ? this.setState({
          klinik: k.data.data,
        })
        : Notif("e", "data tidak ditemukan");
    });
  };
  checkStatus = async () => {
    let f = new FormData()
    f.append('norm', this.state.norm)
    await Api.post("pasien/status", f).then((k) => {
      k.data.status === 0
        ? this.setState(
          {
            redirect: true,
          },
          () => {
            Notif(
              "d",
              "Pasien sudah mendaftar namun masih pending. Silahkan menunggu. Data anda sedang kami verifikasi"
            );
          }
        )
        : k.data.status === 1
          ? this.setState(
            {
              redirect: true,
            },
            () => {
              Notif(
                "e",
                "Pasien sudah mendaftar namun ditolak. Silahkan Reset dan Mendaftar kembali"
              );
            }
          )
          : k.data.status === 2
            ? this.setState(
              {
                redirect: true,
              },
              () => {
                Notif(
                  "s",
                  "Pasien Sudah diterima. Silahkan Menuju Klinik Tujuan anda"
                );
              }
            )
            : this.setState({ status: true }
            );
    });
  };
  componentDidMount = () => {
    this.checkStatus();
    this.listKlinik();
  };
  ganti = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  selectChange = (e) => {
    this.setState({ [e.name]: e.value });
  };
  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        700,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  daftarkan = async (e) => {
    e.preventDefault(e);
    this.checkStatus();
    if (this.state.status) {
      const fotoKTP = await this.resizeFile(this.state.fotoKTP[0]);
      const fotoRujukan = this.state.fotoRujukan
        ? await this.resizeFile(this.state.fotoRujukan[0])
        : false;
      const fotoKartuKontrol = this.state.fotoKartuKontrol
        ? await this.resizeFile(this.state.fotoKartuKontrol[0])
        : false;
      await Api.post("pasien/jlh", {
        tanggal_antri: this.state.tanggal_antri,
        tujuan: this.state.tujuan,
      }).then(async (jlh) => {
        if (jlh.data.status) {
          this.setState({ loading: true });
          let pInsert = new FormData();
          pInsert.append("nama", this.state.nama);
          pInsert.append("norm", this.state.norm);
          pInsert.append("wa", this.state.wa);
          pInsert.append("jenis_kelamin", this.state.jekel);
          pInsert.append(
            "alamat",
            this.state.jl +
            ", " +
            this.state.desa +
            ", RT " +
            this.state.RT +
            ", RW " +
            this.state.RW
          );
          pInsert.append(
            "tanggal_lahir",
            moment(this.state.tglLahir).format("YYYY-MM-DD")
          );
          pInsert.append("tanggal_antri", this.state.tanggal_antri);
          pInsert.append("jenisKelamin", this.state.jekel);
          pInsert.append("penjamin", this.state.penjamin);
          pInsert.append("tujuan", this.state.tujuan);
          pInsert.append("fotoKTP", fotoKTP ? fotoKTP : false);
          pInsert.append("fotoRujukan", fotoRujukan ? fotoRujukan : false);
          pInsert.append(
            "fotoKartuKontrol",
            fotoKartuKontrol ? fotoKartuKontrol : false
          );
          await Api.post("pasien/insert", pInsert).then((i) => {
            this.setState({ loading: false });
          });
          this.componentDidMount();
        } else {
          Notif(
            "d",
            "Tanggal antrian untuk " +
            this.state.tujuan +
            " sudah penuh. Silahkan memilih tanggal yang lain. Terima kasih atas pengertian anda :)"
          );
        }
      });
    } else {
      this.setState({ redirect: true });
    }
  };
  render() {
    const penjamin = [
      { value: "bpjs", label: "BPJS", name: "penjamin" },
      { value: "umum", label: "Umum", name: "penjamin" },
    ];

    var yesterday = moment().subtract(1, "day");
    var jam = moment().subtract("00:15:00");
    var minggu = moment().subtract(-7, "day");
    var valid = function (current) {
      let tanggalvalid =
        current.isAfter(yesterday) &&
        current.isAfter(jam) &&
        current.isBefore(minggu) &&
        !current.isBetween(
          moment().weekday(6, "day"),
          moment().weekday(7, "day")
        );
      return tanggalvalid;
    };
    let preKTP = <i className="fa fa-plus"></i>;
    if (this.state.preKTP) {
      preKTP = (
        <img
          width="100%"
          height="120%"
          className=""
          src={this.state.preKTP}
          alt="preKTP"
        />
      );
    }
    let preRujukan = <i className="fa fa-plus"></i>;
    if (this.state.preRujukan) {
      preRujukan = (
        <img
          width="100%"
          height="120%"
          className=""
          src={this.state.preRujukan}
          alt="preKTP"
        />
      );
    }
    let preKartuKontrol = <i className="fa fa-plus"></i>;
    if (this.state.preKartuKontrol) {
      preKartuKontrol = (
        <img
          width="100%"
          height="120%"
          className=""
          src={this.state.preKartuKontrol}
          alt="preKTP"
        />
      );
    }
    if (this.state.redirect) {
      return <Redirect to="/selesai" />;
    }

    return (
      <>
        <div className="position-relative">
          <Loading loading={this.state.loading} />
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-0 shape-dark">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="10">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo2.png")}
                      style={{ width: "200px" }}
                    />
                    <p className="lead text-white">
                      Silahkan lengkapi form berikut.
                    </p>
                    <div className="btn-wrapper mt-1">
                      <Form onSubmit={this.daftarkan}>
                        <Col md="12" className="text-left">
                          <label>
                            <small className="text-white">
                              Nomor Rekam Medik
                            </small>
                          </label>
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              placeholder="Nomor Rekam Medik"
                              type="number"
                              value={this.state.norm}
                              disabled
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>
                              <small className="text-white">Nama Lengkap</small>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Nama Lengkap"
                              type="text"
                              value={this.state.nama}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>
                              <small className="text-white">
                                Jenis Kelamin
                              </small>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Alamat"
                              type="text"
                              disabled
                              value={this.state.jekel}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>
                              <small className="text-white">
                                Tanggal Lahir
                              </small>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Tanggal Lahir"
                              type="text"
                              disabled
                              value={moment(this.state.tglLahir).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>
                              <small className="text-white">Alamat</small>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Alamat"
                              type="text"
                              disabled
                              value={
                                this.state.jl +
                                ", " +
                                this.state.desa +
                                ", RT " +
                                this.state.RT +
                                ", RW " +
                                this.state.RW
                              }
                            />
                          </FormGroup>
                          <hr
                            style={{
                              background: "white",
                            }}
                          />
                          <FormGroup>
                            <Select
                              options={penjamin}
                              placeholder="Pilih Penjamin"
                              onChange={this.selectChange}
                              isSearchable={false}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Select
                              options={this.state.klinik}
                              placeholder="Pilih Tujuan"
                              onChange={this.selectChange}
                              isSearchable={false}
                            />
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "Pilih Tanggal Antrian",
                                  readOnly: true
                                }}
                                timeFormat={false}
                                isValidDate={valid}
                                closeOnSelect={true}
                                onChange={(e) =>
                                  this.setState({
                                    tanggal_antri: moment(e._d).format(
                                      "YYYY-MM-DD"
                                    ),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <label>
                              <small className="text-white">Nomor WhatsApp</small>
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Input No Whatsapp Aktif"
                              type="number"
                              name="wa"
                              value={this.state.wa}
                              onChange={(e) => {
                                this.ganti(e)
                                localStorage.setItem('nohp', e.target.value)
                              }}
                              required={true}
                            />
                          </FormGroup>
                        </Col>
                        <Row>
                          <Col md="4">
                            <Label className="text-white">
                              Unggah Foto KTP
                            </Label>
                            <InputFiles
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                height: "70px",
                                cursor: "pointer",
                              }}
                              onChange={(files) => {
                                this.setState({
                                  fotoKTP: files,
                                  preKTP: URL.createObjectURL(files[0]),
                                });
                              }}
                            >
                              {preKTP}
                            </InputFiles>
                          </Col>
                          <Col md="4">
                            <Label className="text-white">
                              Unggah Foto Surat Rujukan
                            </Label>
                            <InputFiles
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                height: "70px",
                                cursor: "pointer",
                              }}
                              onChange={(files) => {
                                this.setState({
                                  fotoRujukan: files,
                                  preRujukan: URL.createObjectURL(files[0]),
                                });
                              }}
                            >
                              {preRujukan}
                            </InputFiles>
                          </Col>
                          <Col md="4">
                            <Label className="text-white">
                              Unggah Foto BPJS
                            </Label>
                            <InputFiles
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                height: "70px",
                                cursor: "pointer",
                              }}
                              onChange={(files) => {
                                this.setState({
                                  fotoKartuKontrol: files,
                                  preKartuKontrol: URL.createObjectURL(
                                    files[0]
                                  ),
                                });
                              }}
                            >
                              {preKartuKontrol}
                            </InputFiles>
                          </Col>
                        </Row>
                        <br />

                        <Button
                          className="btn-icon mb-sm-0"
                          color="github"
                          type="submit"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-arrow-right" />
                          </span>
                          <span className="btn-inner--text">
                            <span className="text-success mr-1">Daftarkan</span>
                          </span>
                        </Button>
                      </Form>
                    </div>
                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *Note: <br /> Silahkan mengisi formulir dengan lengkap.
                        Pasien bisa mendaftar hari ini untuk maksimal 1 minggu
                        kedepan. Perhatikan tanggal antrian/kedatangan anda.
                        Anda bisa datang kerumah sakit sesuai tanggal yang anda
                        pilih.
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}
