import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Input,
  Table,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import Api from "../../services/Api";
import moment from "moment";
import ReactDatetime from "react-datetime";
//import Notif from '../../components/Notif/Notif';
import Verify from "../../components/Modals/Verify";
import Loading from "../../components/Notif/LoadingSpiner";
import ModalKlinik from '../../components/Modals/ListKlinik'

class Hero extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    if (localStorage.getItem("user")) {
      this.state = {
        user: JSON.parse(localStorage.getItem('user')),
        redirect: false,
        pendaftar: [],
        search: "",
        d: ">=",
        limit: 10,
        loading: false,
        success: false,
        ps: true,
        tanggal_antri: moment(new Date()).format("YYYY-MM-DD"),
        cKlinik: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
  }
  listPendaftar = async () => {
    this.setState({ loading: true });
    let f = new FormData();
    f.append('token', this.state.user.token)
    f.append('limit', this.state.limit)
    f.append('search', this.state.search)
    f.append('d', this.state.d)
    await Api.post("admin/listpendaftar", f).then((s) => {
      if (s.data.success) {
        this.setState({
          pendaftar: s.data.data,
          loading: false,
          success: true,
        });
      } else {
        this.setState({
          loading: false,
          success: false,
        });
      }
    });
  };
  listCountKlinik = async () => {
    this.setState({
      loading: true,
    });
    let l = new FormData()
    l.append('tanggal_antri', this.state.tanggal_antri)
    await Api.post("count/countKlinik", l).then((s) => {
      if (s.data.success) {
        this.setState({
          cKlinik: s.data.data,
          loading: false,
          success: true,
        });
      } else {
        this.setState({
          loading: false,
          success: false,
        });
      }
    });
  };
  componentDidMount = () => {
    this.listPendaftar();
    let loadUlang = setInterval(() => {
      this.listPendaftar();
    }, 120000);
    this.setState({ loadUlang: loadUlang });
  };
  componentWillUnmount = () => {
    clearInterval(this.state.loadUlang);
  };
  ganti = (e) => {
    e.preventDefault(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onCari = (e) => {
    e.preventDefault(e);
    this.listPendaftar();
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    let listPendaftar = (
      <tr>
        <td>#</td>
        <td>Kosong</td>
        <td>Kosong</td>
        <td>Kosong</td>
        <td>Kosong</td>
        <td>Kosong</td>
        <td>Kosong</td>
      </tr>
    );
    if (!this.state.success) {
      listPendaftar = (
        <tr>
          <td>#</td>
          <td>Kosong</td>
          <td>Kosong</td>
          <td>Kosong</td>
          <td>Kosong</td>
          <td>Kosong</td>
          <td>Kosong</td>
        </tr>
      );
    }
    let dc = "secondary";
    let cd = "primary";
    if (this.state.d === ">=") {
      dc = "primary";
      cd = "secondary";
    }
    if (this.state.success) {
      listPendaftar = this.state.pendaftar.map((data, key) => {
        var str = data.norm;
        var pad = "00000000";
        var ans = pad.substring(0, pad.length - str.length) + str;
        let status = "Belum Diverifikasi";
        let warna = "warning";
        if (data.status === "0") {
          status = "Belum Diverifikasi";
          warna = "warning";
        }
        if (data.status === "1") {
          status = "Diterima";
          warna = "success";
        }
        if (data.status === "2") {
          status = "Ditolak";
          warna = "danger";
        }

        return (
          <tr
            style={{
              cursor: "pointer",
            }}
            key={key}
          >
            <td>{key + 1}</td>
            <td>
              {ans}/{data.id}-{moment(data.tanggal_antri).format("DD-MM-YYYY")}
              <div>
                <small className="text-danger">{data.admin}</small>
              </div>
            </td>
            <td>{data.nama}</td>
            <td>{data.alamat}</td>
            <td>
              {data.tujuan}/{data.penjamin}/{data.wa}
            </td>
            <td>
              {moment(data.tanggal_lahir).format("DD-MM-YYYY")}/{data.umur}{" "}
              Tahun
            </td>
            <td>
              <Verify
                warna={warna}
                status={status}
                norm={data.norm}
                nama={data.nama}
                alamat={data.alamat}
                tujuan={data.tujuan}
                penjamin={data.penjamin}
                id={data.id}
                tanggalAntri={moment(data.tanggal_antri).format("DD-MM-YYYY")}
                refresh={this.listPendaftar}
              />
              <div>
                <small>{data.pesan}</small>
              </div>
            </td>
          </tr>
        );
      });
    }
    let containerPendaftar = (
      <Container className="mt-5">
        <Form onSubmit={this.onCari}>
          <Row>
            <Col md="2 p-md-2">
              <ButtonGroup>
                <Button
                  className="btn-icon btn-2"
                  color={cd}
                  type="button"
                  onClick={() => {
                    this.setState(
                      {
                        d: "<",
                        limit: 10,
                      },
                      () => this.listPendaftar()
                    );
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-calendar-minus-o" />
                  </span>
                </Button>
                <Button
                  className="btn-icon btn-2"
                  color={dc}
                  type="button"
                  onClick={() => {
                    this.setState(
                      {
                        d: ">=",
                        limit: 10,
                      },
                      () => this.listPendaftar()
                    );
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-calendar-plus-o" />
                  </span>
                </Button>
              </ButtonGroup>
            </Col>
            <Col md="6 p-md-2">
              <FormGroup>
                <Input
                  id="exampleFormControlInput1"
                  placeholder="Cari Nama / No. RM"
                  type="text"
                  name="search"
                  value={this.state.search}
                  onChange={this.ganti}
                />
              </FormGroup>
            </Col>

            <Col md="3 p-md-2">
              <FormGroup>
                <Input
                  placeholder="Limit"
                  type="number"
                  name="limit"
                  value={this.state.limit}
                  onChange={this.ganti}
                />
              </FormGroup>
            </Col>
            <Col md="1 p-md-2">
              <Button
                block
                className="btn-icon btn-2 mb-2"
                color="info"
                type="submit"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-search" />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>

        <Table
          responsive
          bordered
          className="text-center mb-0 pb-0"
          style={{
            backgroundColor: "white",
          }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>No RM/No Registrasi</th>
              <th>Nama</th>
              <th>Alamat</th>
              <th>Tujuan/Penjamin</th>
              <th>Tanggal Lahir/ Umur</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{listPendaftar}</tbody>
        </Table>
        <Button
          block
          color="primary"
          size="sm"
          type="button"
          className="mt-0 pt-0"
          onClick={() => {
            this.setState(
              {
                limit: parseInt(this.state.limit) + 3,
              },
              () => this.listPendaftar()
            );
          }}
        >
          <i className="fa fa-sort-desc"></i>
        </Button>
      </Container>
    );
    if (!this.state.ps) {
      containerPendaftar = (
        <div>
          <div className="container col-lg-4 mt-3">
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Pilih Tanggal Antrian",
                  }}
                  timeFormat={false}
                  closeOnSelect={true}
                  value={this.state.tanggal_antri}
                  onChange={(e) =>
                    this.setState(
                      {
                        tanggal_antri: moment(e._d).format("YYYY-MM-DD"),
                      },
                      () => {
                        this.listCountKlinik();
                      }
                    )
                  }
                />
              </InputGroup>
            </FormGroup>
          </div>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="cardStatistik verifikasi col-lg-3 m-1">
              <span>Di VERIFIKASI</span>
              <br />
              <span className="judulStats">
                {this.state.cKlinik && this.state.cKlinik.pending}
                {this.state.cKlinik.pending === null && "0"}
              </span>
            </div>
            <div className="cardStatistik tolak col-lg-3  m-1">
              <span>Di TOLAK</span>
              <br />
              <span className="judulStats">
                {this.state.cKlinik && this.state.cKlinik.tolak}
                {this.state.cKlinik.tolak === null && "0"}
              </span>
            </div>
            <div className="cardStatistik terima col-lg-3  m-1">
              <span>Di TERIMA</span>
              <br />
              <span className="judulStats">
                {this.state.cKlinik && this.state.cKlinik.terima}
                {this.state.cKlinik.terima === null && "0"}
              </span>
            </div>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>INTERNA</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.interna === null ? "0" : this.state.cKlinik.interna}
                  tujuan={'KLINIK INTERNA'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>MATA</span>
              <br />
              <span className="judulStats">

                <ModalKlinik
                  jlh={this.state.cKlinik.mata === null ? "0" : this.state.cKlinik.mata}
                  tujuan={'KLINIK MATA'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>THT</span>
              <br />
              <span className="judulStats">

                <ModalKlinik
                  jlh={this.state.cKlinik.THT === null ? "0" : this.state.cKlinik.THT}
                  tujuan={'KLINIK THT'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>ANAK</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.anak === null ? "0" : this.state.cKlinik.anak}
                  tujuan={'KLINIK ANAK'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>BEDAH</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.bedah === null ? "0" : this.state.cKlinik.bedah}
                  tujuan={'KLINIK BEDAH'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>KIA/KB</span>
              <br />
              <span className="judulStats">

                <ModalKlinik
                  jlh={this.state.cKlinik.obgyn === null ? "0" : this.state.cKlinik.obgyn}
                  tujuan={'KLINIK OBGYN'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>FISIOTERAPI</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.fisioterapi === null ? "0" : this.state.cKlinik.fisioterapi}
                  tujuan={'KLINIK FISIO TERAPI'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>UMUM</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.umum === null ? "0" : this.state.cKlinik.umum}
                  tujuan={'KLINIK UMUM'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>PSIKOLOGI</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.psikologi === null ? "0" : this.state.cKlinik.psikologi}
                  tujuan={'KLINIK PSIKOLOGI'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>GIGI</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.gigi === null ? "0" : this.state.cKlinik.gigi}
                  tujuan={'KLINIK GIGI'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>JANTUNG</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.jantung === null ? "0" : this.state.cKlinik.jantung}
                  tujuan={'KLINIK JANTUNG'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>SARAF</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.saraf === null ? "0" : this.state.cKlinik.saraf}
                  tujuan={'KLINIK SARAF'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>REHAB MEDIK</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.rehabmedik === null ? "0" : this.state.cKlinik.rehabmedik}
                  tujuan={'KLINIK REHAB MEDIK'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
            <div className="cardStatistik cardKlinik col-lg-2 col-sm-4 m-1">
              <span>KULIT KELAMIN</span>
              <br />
              <span className="judulStats">
                <ModalKlinik
                  jlh={this.state.cKlinik.kulitkelamin === null ? "0" : this.state.cKlinik.kulitkelamin}
                  tujuan={'KLINIK KULIT DAN KELAMIN'}
                  token={this.state.user.token}
                  tgl={this.state.tanggal_antri}
                />
              </span>
            </div>
          </Row>
        </div>
      );
    }
    return (
      <>
        <div className="position-relative">
          <Loading loading={this.state.loading} />
          {/* Hero for FREE version */}
          <section className={"section pb-2 bg-gradient-default"}>
            <Container
              className="shape-container d-flex align-items-center py-lg"
              fluid
            >
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="10">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo2.png")}
                      style={{ width: "200px" }}
                    />
                    <p className="lead text-white">
                      Daftar Pendaftar Rawat Jalan Online
                    </p>
                    <ButtonGroup>
                      <Button
                        className="btn-icon btn-2"
                        color={this.state.ps ? "primary" : "secondary"}
                        type="button"
                        onClick={() => {
                          this.setState(
                            {
                              ps: true,
                            },
                            () => this.listPendaftar()
                          );
                        }}
                      >
                        <span className="btn-inner--icon pl-5">
                          <i className="fa fa-th-list" />
                        </span>
                      </Button>
                      <Button
                        className="btn-icon btn-2"
                        color={!this.state.ps ? "primary" : "secondary"}
                        type="button"
                        onClick={() => {
                          this.setState(
                            {
                              ps: false,
                            },
                            () => this.listCountKlinik()
                          );
                        }}
                      >
                        <span className="btn-inner--icon pr-5">
                          <i className="fa fa-pie-chart" />
                        </span>
                      </Button>
                    </ButtonGroup>
                    {containerPendaftar}
                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *Note: <br /> Daftar pasien yang muncul adalah daftar
                        pasien yang akan datang hari ini dan hari sebelumnya.
                        Pastikan data yang di input oleh pasien memenuhi unsur
                        standar pendaftaran.
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
