import { toast } from 'react-toastify';

export default function Notif(indicator, icon, namaP, pesan) {
	if (indicator === 's') {
		toast.success(icon, {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	if (indicator === 'd') {
		toast(icon, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	if (indicator === 'w') {
		toast.warning(icon, {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	if (indicator === 'e') {
		toast.error(icon, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}
