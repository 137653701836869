import React, { Component } from 'react';
import DemoNavbar from 'components/Navbars/DemoNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter';
import Admin from 'views/admin/Admin';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default class index extends Component {
	render() {
		return (
			<div>
				<DemoNavbar />
				<Admin />
				<ToastContainer />
				<SimpleFooter />
			</div>
		);
	}
}
