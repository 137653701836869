import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

export default class LoadingSpiner extends Component {
	render() {
		if (this.props.loading === true) {
			return (
				<div className="loadings">
					<div>
						<Spinner color="primary" />
						<Spinner color="success" />
						<Spinner color="danger" />
						<Spinner color="warning" />
						<Spinner color="info" />
						<Spinner color="dark" />
					</div>
					<div className="text-center text-warning">Mohon untuk menunggu....</div>
				</div>
			);
		} else {
			return <></>;
		}
	}
}
