
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import 'assets/scss/argon-design-system-react.scss?v1.1.0';
import 'assets/css/custom.css';

import Index from 'views/Index.js';
import Mulai from 'views/mulai/index';
import Registrasi from 'views/registrasi/index';
import Selesai from 'views/selesai/index';
import Admin from 'views/admin/index';
import Login from 'views/admin/Login';

ReactDOM.render(
	<HashRouter>
		<Switch>
			<Route path="/" exact render={(props) => <Index {...props} />} />

			<Route path="/mulai" exact render={(props) => <Mulai {...props} />} />
			<Route path="/mulai/registrasi" exact render={(props) => <Registrasi {...props} />} />
			<Route path="/selesai" exact render={(props) => <Selesai {...props} />} />
			<Route path="/pengelolah" exact render={(props) => <Admin {...props} />} />
			<Route path="/login" exact render={(props) => <Login {...props} />} />
			<Redirect to="/" />
		</Switch>
	</HashRouter>,
	document.getElementById('root')
);
