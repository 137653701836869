
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import Jadwal from "../components/Modals/Jadwal";
class Hero extends React.Component {
  state = {
    jadwal: [],
  };
  componentDidMount = () => {
    this.forceUpdate()
  }
  render() {
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-dark">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo2.png")}
                      style={{ width: "200px" }}
                    />
                    <p className="lead text-white">
                      Pendaftaran Online Rawat Jalan RSUD Kotamobagu.
                    </p>
                    <div className="btn-wrapper mt-5">
                      <Link to="/mulai">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="github"
                          size="lg"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-play-circle " />
                          </span>
                          <span className="btn-inner--text">
                            <span className="text-warning mr-1">
                              &nbsp; &nbsp; &nbsp; Mulai
                            </span>
                            !&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          </span>
                        </Button>
                      </Link>
                      <Jadwal jadwal={this.state.jadwal} />
                    </div>
                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *Note: <br /> Siapkan KTP dan Nomor Rekam Medik anda.
                        Jika Pasien BPJS, Siapkan surat rujukan dari faskes
                        sebelumnya.
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
