import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Badge,
  UncontrolledAlert,
  Progress,
} from "reactstrap";
//config
import Api from "../../services/Api";

//component
import Notif from "../Notif/Notif";
import moment from "moment";

export default class ModalHapus extends Component {
  state = {
    verifyModal: false,
    tombol: this.props.tombol,
    data: [{ fotoKTP: false, fotoRujukan: false, fotoKartuKontrol: false }],
    pesan: localStorage.getItem('pesan') ? localStorage.getItem('pesan') : ''

  };
  detailPendaftar = async () => {
    let pVerify = new FormData();
    pVerify.append("email", this.state.user.data.nama);
    pVerify.append("token", this.state.user.token);
    pVerify.append("id", this.props.id);
    await Api.post("admin/pendaftarDetail", pVerify).then((v) => {
      this.setState({
        data: v.data.data,
        loading: false,
      });
    });
  };
  toggleModal = (state) => {
    this.setState(
      {
        [state]: !this.state[state],
        loading: true,
        user: JSON.parse(localStorage.getItem('user'))
      },
      () => {
        this.detailPendaftar();

      }
    );
  };
  ganti = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  verify = async (e) => {
    this.setState({
      loading: true,
    });
    let pVerify = new FormData();
    pVerify.append("email", this.state.user.data.nama);
    pVerify.append("token", this.state.user.token);
    pVerify.append("pesan", this.state.pesan);
    pVerify.append("id", this.props.id);
    pVerify.append("wa", this.state.data.wa);
    pVerify.append("nama", this.props.nama);
    pVerify.append("tujuan", this.props.tujuan);
    pVerify.append("penjamin", this.props.penjamin);
    pVerify.append("status", this.state.status);
    await Api.post("admin/verifikasi", pVerify);
    this.props.refresh();
    this.toggleModal("verifyModal");
    if (this.state.indicator) {
      Notif(
        this.state.indicator,
        this.state.icon,
        sessionStorage.getItem("namaAdmin"),
        this.state.message
      );
    }
  };

  render() {
    let Spiner = "";
    if (this.state.loading) {
      Spiner = <Progress animated color="danger" value="100" />;
    }
    let FotoRujukan = (
      <UncontrolledAlert color="warning" fade={false}>
        <span className="alert-inner--icon">
          <i className="ni ni-image" />
        </span>
        <span className="alert-inner--text">
          <strong>Foto Rujukan tidak diupload!</strong>
        </span>
      </UncontrolledAlert>
    );
    if (this.state.data.fotoRujukan !== "false" && !this.state.loading) {
      FotoRujukan = (
        <a
          style={{
            cursor: "pointer",
          }}
          href={this.state.data.fotoRujukan}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="100%"
            height="120%"
            className="thumbnail"
            src={this.state.data.fotoRujukan}
            alt="preKTP"
          />
        </a>
      );
    }
    let FotoKTP = (
      <UncontrolledAlert color="warning" fade={false}>
        <span className="alert-inner--icon">
          <i className="ni ni-image" />
        </span>
        <span className="alert-inner--text">
          <strong>KTP tidak diupload!</strong>
        </span>
      </UncontrolledAlert>
    );
    if (this.state.data.fotoKTP !== "false" && !this.state.loading) {
      FotoKTP = (
        <a
          style={{
            cursor: "pointer",
          }}
          href={this.state.data.fotoKTP}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="100%"
            height="120%"
            className="thumbnail"
            src={this.state.data.fotoKTP}
            alt="preKTP"
          />
        </a>
      );
    }
    let FotoKartuKontrol = (
      <UncontrolledAlert color="warning" fade={false}>
        <span className="alert-inner--icon">
          <i className="ni ni-image" />
        </span>
        <span className="alert-inner--text">
          <strong>BPJS tidak diupload!</strong>
        </span>
      </UncontrolledAlert>
    );
    if (this.state.data.fotoKartuKontrol !== "false" && !this.state.loading) {
      FotoKartuKontrol = (
        <a
          style={{
            cursor: "pointer",
          }}
          href={this.state.data.fotoKartuKontrol}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="100%"
            height="120%"
            className="thumbnail"
            src={this.state.data.fotoKartuKontrol}
            alt="preKTP"
          />
        </a>
      );
    }
    var str = this.props.norm;
    var pad = "00000000";
    var ans = pad.substring(0, pad.length - str.length) + str;
    var today = moment().format("DD-MM-YYYY");

    let disabled = false;
    let tdisabled = false;
    if (today < this.props.tanggalAntri) {
      tdisabled = false;
    }
    if (
      today < this.props.tanggalAntri &&
      this.props.status !== "Belum Diverifikasi"
    ) {
      disabled = true;
    }
    return (
      <>
        <Button
          color={this.props.warna}
          type="button"
          size="sm"
          onClick={() => this.toggleModal("verifyModal")}
        >
          <strong>{this.props.status}</strong>
        </Button>

        <Modal
          className="modal-dialog-centered"
          //contentClassName="bg-gradient-default"
          isOpen={this.state.verifyModal}
          toggle={() => this.toggleModal("verifyModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Verifikasi Data
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("verifyModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <i className="ni ni-bell-55 ni-2x" />
              <h4 className="heading">Verifikasi Data Pendaftar</h4>
              <hr className="mb-3 p-0" />
              <div className="text-left">
                <p className="m-0 p-0">
                  No RM&ensp;&ensp;: <strong>{ans}</strong>
                </p>
                <p className="m-0 p-0">
                  Nama &ensp;&ensp;: <strong>{this.props.nama}</strong>
                </p>
                <p className="m-0 p-0">
                  Alamat &ensp;: <strong>{this.props.alamat}</strong>
                </p>
                <p className="m-0 p-0">
                  Tujuan/Penjamin &ensp;:{" "}
                  <strong>
                    {this.props.tujuan}/{this.props.penjamin}/{this.state.data.wa}
                  </strong>
                </p>
                <p className="m-0 p-0">
                  Tanggal Daftar &ensp;:{" "}
                  <strong>{this.state.data.tanggal_daftar}</strong>
                </p>
                <p className="m-0 p-0">
                  Tanggal Antri &ensp;:{" "}
                  <strong>{this.state.data.tanggal_antri}</strong>
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Badge color="secondary" className="mb-1">
                    {FotoKTP}
                    {Spiner}
                  </Badge>
                  <Badge color="secondary">
                    {FotoRujukan} {Spiner}
                  </Badge>
                  <Badge color="secondary" className="mt-1">
                    {FotoKartuKontrol} {Spiner}
                  </Badge>
                </div>

                <Form>
                  <label className="mt-3">
                    Silahkan Input Alasan Penolakan/Penerimaan
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Silahkan Input Alasan Penolakan/Penerimaan"
                    rows="3"
                    type="textarea"
                    name="pesan"
                    onChange={e => {
                      this.ganti(e)
                      localStorage.setItem('pesan', e.target.value)
                    }}
                    value={this.state.pesan}
                    required
                  />
                  <div className="modal-footer">
                    <Button
                      color="danger"
                      disabled={disabled}
                      type="button"
                      onClick={() => {
                        if (this.state.pesan) {
                          this.setState(
                            {
                              status: 2,
                            },
                            () => this.verify()
                          );
                        } else {
                          Notif("e", "Pesan jangan kosong !");
                        }
                      }}
                    >
                      Tolak
                    </Button>
                    <Button
                      className="text-white ml-auto"
                      color="success"
                      disabled={tdisabled}
                      type="button"
                      onClick={() => {
                        if (this.state.pesan) {
                          this.setState(
                            {
                              status: 1,
                            },
                            () => this.verify()
                          );
                        } else {
                          Notif("e", "Pesan jangan kosong !");
                        }
                      }}
                    >
                      Terima
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
