import axios from 'axios';
import { WS_SIMRS } from '../config';

export default axios.create({
	baseURL: WS_SIMRS,

	// headers: {
	// 	'Access-Control-Allow-Origin': 'simrs.kotamobagukota.go.id',
	// 	'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept,Set-Cookie',
	// 	'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
	// 	'Content-Type': 'application/json',
	// 	Accept: '*/*',
	// },
	//withCredentials: true,
});
