import React, { Component } from "react";
import { Button, Modal, Table, FormGroup } from "reactstrap";
import moment from "moment";
import Api2 from "../../services/Api2";
import Select from "react-select";
export default class Jadwal extends Component {
  state = {
    exampleModal: false,
  };
  toggleModal = async (state) => {
    this.setState({
      [state]: !this.state[state],
      hari: this.Hari(),
    }, () => {
      this.JadwalApi()
    });
  };
  JadwalApi = async () => {
    let f = new FormData()
    f.append('hari', this.state.hari)
    await Api2.post("pasien/jadwal", f)
      .then((res) => {
        this.setState({
          jadwal: res.data,
        });
      })
      .catch(function (error) {
        console.log("Show error notification!");
        return Promise.reject(error);
      });
  }
  Hari = () => {
    let today = moment().format("dddd");
    if (today === 'Monday') {
      return 'Senin'
    }
    if (today === 'Tuesday') {
      return 'Selasa'
    }
    if (today === 'Wednesday') {
      return 'Rabu'
    }
    if (today === 'Thursday') {
      return 'Kamis'
    }
    if (today === 'Friday') {
      return 'Jumat'
    }
    if (today === 'Saturday') {
      return 'Sabtu'
    } else {
      return 'Senin'
    }
  }
  selectChange = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.JadwalApi()
    });
  };
  render() {
    const penjamin = [
      { value: "Senin", label: "Senin", name: "hari" },
      { value: "Selasa", label: "Selasa", name: "hari" },
      { value: "Rabu", label: "Rabu", name: "hari" },
      { value: "Kamis", label: "Kamis", name: "hari" },
      { value: "Jumat", label: "Jumat", name: "hari" },
      { value: "Sabtu", label: "Sabtu", name: "hari" },

    ];
    let jadwal = (
      <tr>
        <th scope="row">-</th>
        <td>-</td>
        <td>-</td>
      </tr>
    );
    if (this.state.jadwal) {
      jadwal = this.state.jadwal.data.map((data, key) => {
        return (
          <tr key={key}>
            <th scope="row">{key + 1}</th>
            <td>{data.klinik}</td>
            <td>{data.nama_dokter}</td>
          </tr>
        );
      });
    }

    return (
      <>
        <Button
          className="btn-icon mb-3 mb-sm-0 ml-2"
          color="github"
          size="lg"
          onClick={() => this.toggleModal("exampleModal")}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-user-md" />
          </span>
          <span className="btn-inner--text">
            <span className="text-warning mr-1">Jadwal Dokter</span>
          </span>
        </Button>
        {/* Modal */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Jadwal Dokter
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormGroup>
              <Select
                options={penjamin}
                placeholder="Pilih Hari"
                onChange={this.selectChange}
                isSearchable={false}
              />
            </FormGroup>
            <h5 className="text-center">Jadwal Hari {this.state.hari}</h5>
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Klinik</th>
                  <th>Dokter</th>
                </tr>
              </thead>
              <tbody>
                {jadwal}
              </tbody>
            </Table>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              Close
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              Oke
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
