import React, { Component } from "react";
import { Button, Container, Row, Col, FormGroup, Input } from "reactstrap";

import Api2 from "../../services/Api2";
import { Redirect } from "react-router-dom";
import Notif from "../../components/Notif/Notif";
import Loading from "../../components/Notif/LoadingSpiner";

export default class Hore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      norm: sessionStorage.getItem("norm")
        ? sessionStorage.getItem("norm")
        : "",
      data: [],
      success: "",
      redirect: false,
      loading: false,
    };
  }
  rekamMedik = async () => {
    this.setState({
      loading: true,
    });
    if (this.state.norm !== "") {
      let f = new FormData();
      f.append('norm', this.state.norm)
      await Api2.post("pasien/check", f).then((res) => {
        res.data.success === true
          ? this.setState(
            {
              success: true,
              pesan: ", Silahkan mengisi Formulir Berikut ya.. 😃",
            },
            () => {
              sessionStorage.setItem("data", JSON.stringify(res.data.data));
              Notif("d", "✔️ Hai " + res.data.data.NAMA);
              Notif("d", "Data Anda Telah Kami Temukan ");
              Notif("d", "Silahkan mengisi Formulir Berikut ya.. 😃");
            }
          )
          : res.data.success === false
            ? this.setState(
              {
                data: null,
                success: false,
                pesan: "Data tidak ditemukan!",
                loading: false,
              },
              () => {
                Notif(
                  "e",
                  "Data anda Tidak Kami Temukan. Silahkan hubungi loket ya :)"
                );
              }
            )
            : this.setState(
              {
                data: null,
                success: false,
                pesan: "Ada kesalahan!",
                loading: false,
              },
              () => {
                Notif(
                  "e",
                  "Data anda Tidak Kami Temukan. Silahkan hubungi loket ya :)"
                );
              }
            );
      });
    } else {
      this.setState(
        {
          data: null,
          success: false,
          pesan: "Ada kesalahan!",
          loading: false,
        },
        () => {
          Notif("e", "No. Rekam Medik Kosong. Silahkan dinput kembali");
        }
      );
    }
  };
  gantirm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    sessionStorage.setItem("norm", e.target.value);
  };
  componentDidMount = () => { };
  render() {
    if (
      localStorage.getItem("namaAdmin") &&
      localStorage.getItem("emailAdmin")
    ) {
      return <Redirect to="/pengelolah" />;
    }
    if (this.state.success === true) {
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 5000);
    }
    if (this.state.redirect === true) {
      return <Redirect to="/mulai/registrasi" />;
    }

    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            <Loading loading={this.state.loading} />
            {/* Background circles */}
            <div className="shape shape-style-0 shape-dark">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo2.png")}
                      style={{ width: "200px" }}
                    />
                    <p
                      className="lead text-white"
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Silahkan mengisi Nomor Rekam Medik atau NIK KTP.
                    </p>
                    <div className="btn-wrapper mt-1">
                      <Col md="12">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            placeholder="Masukkan Nomor Rekam Medik atau NIK KTP"
                            type="number"
                            name="norm"
                            value={this.state.norm}
                            onChange={this.gantirm}
                          />
                        </FormGroup>
                      </Col>

                      <Button
                        className="btn-icon mb-sm-0"
                        color="github"
                        size="lg"
                        onClick={this.rekamMedik}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-arrow-right" />
                        </span>
                        <span className="btn-inner--text">
                          <span className="text-warning mr-1">
                            Ambil Formulir
                          </span>
                        </span>
                      </Button>
                    </div>

                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *Note: <br /> Siapkan KTP untuk Pasien Umum, Siapkan
                        KTP/BJPS dan surat rujukan dari faskes sebelumnya untuk
                        pasien BPJS dan Jika pasien lama/sudah pernah berobat,
                        siapkan kartu kontrol.
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}
