import Notif from "components/Notif/Notif";
import React from "react";
// reactstrap components
import {
    Button,
    Modal,
    Table
} from "reactstrap";
import moment from "moment";
import Api from 'services/Api'
import Loading from "../../components/Notif/LoadingSpiner";
class Modals extends React.Component {
    state = {
        defaultModal: false,
        data: []
    };
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    listKlinik = () => {
        this.setState({ loading: true })
        let l = new FormData()
        l.append('token', this.props.token)
        l.append('tgl', this.props.tgl)
        l.append('tujuan', this.props.tujuan)
        Api.post('admin/klinik_detail', l)
            .then((d) => {
                let data = d.data
                if (data.status) {
                    this.setState({ data: data.data, loading: false })
                } else {
                    Notif('w', 'Data tidak ditemukan')
                    this.setState({ loading: false })
                }
            })
            .catch((err) => {
                Notif('e', 'ada kesalahan')
                this.setState({ loading: false })
                console.log(err)
            })
    }
    render() {
        let listpendaftar = <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        if (!this.state.loading) {
            listpendaftar = this.state.data.map((data, key) => {
                let penjamin = data.penjamin === 'bpjs' ? <Button size="sm" color="success">BPJS</Button> : <Button size="sm" color="primary">UMUM</Button>
                return (
                    <tr key={key}>
                        <td><p className="h6">{key + 1}</p></td>
                        <td><p className="h6">{data.norm}/{data.id}-{this.props.tgl}</p>{penjamin}</td>
                        <td><p className="h6">{data.nama}</p></td>
                        <td><p className="h6">{data.alamat}</p></td>
                        <td><p className="h6">{moment(data.tanggal_lahir).format("DD-MM-YYYY")}/{data.umur}{" "}
                            Tahun</p></td>
                    </tr>
                )
            })
        }
        return (
            <>

                <Button
                    className="judulStats"
                    color="primary" type="button"
                    onClick={() => {
                        this.toggleModal("defaultModal")
                        this.listKlinik()
                    }}
                >
                    {this.props.jlh}
                </Button>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.defaultModal}
                    toggle={() => this.toggleModal("defaultModal")}
                    size={"lg"}
                >
                    <Loading loading={this.state.loading} />
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            {this.props.tujuan}
                        </h6>

                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("defaultModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Table
                            responsive
                            bordered
                            className="text-center mb-0 pb-0"
                            style={{
                                backgroundColor: "white",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No RM/No Registrasi</th>
                                    <th>Nama</th>
                                    <th>Alamat</th>
                                    <th>Tanggal Lahir/ Umur</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listpendaftar}
                            </tbody>
                        </Table>
                    </div>

                </Modal>
            </>
        );
    }
}

export default Modals;