
import React from "react";
import { Link } from "react-router-dom";
// import { GoogleLogin, GoogleLogout } from "react-google-login";
// import { GOOGLE_ID } from "../../config";
// import Jwt from "../../services/JWT";
// import Api from "../../services/Api2";
// import Notif from "../../components/Notif/Notif";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
//import { Redirect } from 'react-router-dom';

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  // responseGoogle = async (gg) => {
  //   if (!gg.error) {
  //     this.setState({
  //       id_token: gg.tokenId,
  //     });
  //     localStorage.setItem("tokenId", gg.tokenId);

  //     await Jwt.post("oauth2/v3/tokeninfo?", this.state).then((g) => {
  //       this.setState(
  //         {
  //           email: g.data.email,
  //         },
  //         async () => {
  //           await Api.post("admin_api/login", this.state).then((login) => {
  //             if (login.data.status === true) {
  //               this.setState({
  //                 masuk: true,
  //               });
  //               localStorage.setItem(
  //                 "namaAdmin",
  //                 g.data.given_name + " " + g.data.family_name
  //               );
  //               localStorage.setItem("emailAdmin", g.data.email);
  //               Notif(
  //                 "d",
  //                 "✔️" +
  //                 g.data.given_name +
  //                 " Anda Login Sebagai Pengelolah Data "
  //               );
  //             } else {
  //               this.setState(
  //                 {
  //                   masuk: false,
  //                 },
  //                 () => {
  //                   sessionStorage.clear("namaAdmin");
  //                   localStorage.clear("emailAdmin");
  //                 }
  //               );
  //             }
  //           });
  //         }
  //       );
  //     });
  //   }
  // };
  keluar = () => {
    localStorage.removeItem('user');
    this.setState({
      masuk: false,
    });
  };
  render() {
    let TombolKeluar = ''
    if (localStorage.getItem('user')) {
      TombolKeluar = <Button className="btn-icon btn-3" color="primary" type="button">
        <span className="btn-inner--icon">
          <i className="ni ni-bold-left" />
        </span>
        <span className="btn-inner--text" onClick={() => this.keluar()} >Keluar</span>
      </Button>
    }
    // if (sessionStorage.getItem('tokenId') && sessionStorage.getItem('emailAdmin')) {
    // 	return <Redirect to="/olahdata" />;
    // }
    // let TombolGoogle = (
    //   <GoogleLogin
    //     clientId={GOOGLE_ID}
    //     render={(e) => (
    //       <Button
    //         className="btn-neutral btn-icon ml-1"
    //         color="default"
    //         onClick={e.onClick}
    //         disabled={e.disabled}
    //       >
    //         <span className="btn-inner--icon mr-1">
    //           <img
    //             alt="..."
    //             src={require("assets/img/icons/common/google.svg")}
    //           />
    //         </span>
    //         <small className="btn-inner--text">Google</small>
    //       </Button>
    //     )}
    //     buttonText="Login"
    //     onSuccess={this.responseGoogle}
    //     onFailure={this.responseGoogle}
    //     cookiePolicy={"single_host_origin"}
    //     isSignedIn
    //   />
    // );
    // if (this.state.masuk === true) {
    //   TombolGoogle = (
    //     <GoogleLogout
    //       clientId={GOOGLE_ID}
    //       render={(e) => (
    //         <Button
    //           className="btn-neutral btn-icon ml-1"
    //           color="default"
    //           onClick={e.onClick}
    //           disabled={e.disabled}
    //         >
    //           <span className="btn-inner--icon mr-1">
    //             <img
    //               alt="..."
    //               src={require("assets/img/icons/common/google.svg")}
    //             />
    //           </span>
    //           <small className="btn-inner--text">Keluar</small>
    //         </Button>
    //       )}
    //       buttonText="Logout"
    //       onLogoutSuccess={this.keluar}
    //     ></GoogleLogout>
    //   );
    // }
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-4" to="/" tag={Link}>
                <img alt="..." src={require("assets/img/brand/logo2.png")} />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/argon-react.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Menu</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media className="d-flex align-items-center" href="#">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Daftar Antrian
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Daftar Antrian Setiap Klinik.
                            </p>
                          </Media>
                        </Media>
                        <Media className="d-flex align-items-center" href="#">
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-palette" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Ketersediaan Kamar Ranap
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Ketersediaan Kamar Rawat Inap.
                            </p>
                          </Media>
                        </Media>
                        <Media className="d-flex align-items-center" href="#">
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-ui-04" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Jadwal Dokter
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Jadwal Dokter Pada Klinik Rumah Sakit.
                            </p>
                          </Media>
                        </Media>
                        <Link to="/login">
                          <Media
                            className="d-flex align-items-center"

                          >

                            <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                              <i class="fa fa-sign-in"></i>
                            </div>
                            <Media body className="ml-3">
                              <h5 className="heading text-info mb-md-1">
                                Login
                              </h5>
                              <p className="description d-none d-md-inline-block mb-0">
                                Login administrator Simpel Apps
                              </p>
                            </Media>

                          </Media>
                        </Link>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <UncontrolledDropdown nav>
										<DropdownToggle nav>
											<i className="ni ni-collection d-lg-none mr-1" />
											<span className="nav-link-inner--text">Examples</span>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem to="/landing-page" tag={Link}>
												Landing
											</DropdownItem>
											<DropdownItem to="/profile-page" tag={Link}>
												Profile
											</DropdownItem>
											<DropdownItem to="/login-page" tag={Link}>
												Login
											</DropdownItem>
											<DropdownItem to="/register-page" tag={Link}>
												Register
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown> */}
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/pages/RSUD%20Kota%20Kotamobagu/225151101384377/"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Like us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/rsudkk/?hl=af"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip112445449"
                      target="_blank"
                    >
                      <i className="fa fa-github" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Github
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip112445449">
                      Star us on Github
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="d-lg-block ml-lg-4">
                    {TombolKeluar}
                    {/* {TombolGoogle} */}
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
