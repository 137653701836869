import React from "react";

// reactstrap components
import { Container, Row, Col, Label, Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import Api2 from "../../services/Api2";
import moment from "moment";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    if (sessionStorage.getItem("data")) {
      let dataPasien = JSON.parse(sessionStorage.getItem("data"));
      var str = dataPasien.NORM;
      var pad = "00000000";
      var ans = pad.substring(0, pad.length - str.length) + str;
      this.state = {
        nama: dataPasien.NAMA,
        norm: ans,
        tglLahir: dataPasien.TANGGAL_LAHIR,
        jekel: dataPasien.JENIS_KELAMIN,
        jl: dataPasien.ALAMAT,
        desa: dataPasien.WILAYAH,
        RT: dataPasien.RT,
        RW: dataPasien.RW,
        redirect: false,
      };
    } else {
      this.state = {
        redirect: true,
      };
    }
  }
  selesai = async () => {
    let f = new FormData()
    f.append('norm', this.state.norm)
    await Api2.post("pasien/selesai", f).then((s) => {
      if (s.data.success) {
        this.setState({
          status: parseInt(s.data.data.status),
          noreg:
            s.data.data.id +
            "-" +
            moment(s.data.data.tanggal_antri).format("YYYY-MM-DD"),
          tujuan: s.data.data.tujuan,
          tanggal_antri: s.data.data.tanggal_antri,
          pesan: s.data.data.pesan,
          id: s.data.data.id,
          penjamin: s.data.data.penjamin,
        });
      } else {
        this.setState({
          redirect: true,
        });
      }
    });
  };
  componentDidMount = () => {
    this.selesai();
    const updatebaru = setInterval(() => {
      this.selesai();
    }, 5000);
    this.setState({ updatebaru: updatebaru });
  };
  componentWillUnmount = () => {
    clearInterval(this.state.updatebaru);
  };
  reset = async () => {
    // sessionStorage.clear();
    let f = new FormData()
    f.append('id', this.state.id)
    await Api2.post("pasien/hapus", f).then((s) => {
      this.setState({
        redirect: true,
      });
    });
  };
  render() {
    let Reset = "";
    let note = "";
    let kepala = "";
    let warna = "warning";
    if (this.state.status === 0) {
      kepala = "Pending";
      warna = "warning";
      note =
        "Data anda sedang diverifikasi. Mohon berkenan untuk menunggu ya.. :)";
      Reset = (
        <Button
          block
          color="danger"
          size="sm"
          type="button"
          onClick={this.reset}
        >
          Batal
        </Button>
      );
    }
    if (this.state.status === 1) {
      kepala = "Diterima";
      warna = "success";
      note =
        "Pemintaan Registrasi Rawat Jalan anda telah diterima. Terima Kasih";
    }
    if (this.state.status === 2) {
      kepala = "Ditolak";
      warna = "danger";
      note =
        "Permintaan Registrasi Rawat Jalan anda telah ditolak. Silahkan memperhatikan pesan yang telah di submit oleh loket dan silahkan mendaftar kembali.";
      Reset = (
        <Button
          block
          color="danger"
          size="sm"
          type="button"
          onClick={this.reset}
        >
          Daftar Ulang
        </Button>
      );
    }
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className={"section pb-2 bg-gradient-" + warna}>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="10">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo2.png")}
                      style={{ width: "200px" }}
                    />
                    <p className="lead text-white">Pendaftaran Selesai</p>

                    <div className="btn-wrapper mt-1">
                      <Col
                        md="12"
                        style={{
                          backgroundColor: "white",
                          paddingBottom: "30px",
                        }}
                      >
                        <p className="lead text-black mb-0">status:</p>
                        <p className={"heading-title text-" + warna}>
                          {kepala}
                        </p>
                        <hr
                          style={{
                            margin: 0,
                            padding: "5px",
                          }}
                        />
                        <Label className="mb-0 mt-0">No. Registrasi: </Label>
                        <p className="heading text-black mb-0 mt-0 ">
                          <strong>{this.state.noreg}</strong>
                        </p>
                        <Label className="mb-0 mt-0">No. RM/Nama: </Label>
                        <p className="heading text-black mb-0 mt-0 ">
                          <strong>
                            {this.state.norm}/{this.state.nama}
                          </strong>
                        </p>
                        <Label className="mb-0 mt-0">Tujuan/Penjamin:</Label>
                        <p className="heading text-black mb-0 mt-0">
                          <strong>
                            {this.state.tujuan}/{this.state.penjamin}
                          </strong>
                        </p>
                        <Label className="mb-0 mt-0">Tanggal Antrian:</Label>
                        <p className="heading text-black mb-0 mt-0">
                          <strong>
                            {moment(this.state.tanggal_antri).format(
                              "DD-MM-YYYY"
                            )}
                          </strong>
                        </p>
                        <Label className="mb-0 mt-0">pesan:</Label>
                        <p className="heading text-danger mb-0 mt-0">
                          {this.state.pesan}
                        </p>
                        {Reset}
                      </Col>
                    </div>
                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *Note: <br /> {note}
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
