import React, { Component } from 'react';
import DemoNavbar from 'components/Navbars/DemoNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	FormGroup,
	Input,
	CardHeader,
	CardBody,
	Card,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import Api from 'services/Api'
import Notif from 'components/Notif/Notif';
import { Redirect } from 'react-router-dom'
import LoadingSpiner from 'components/Notif/LoadingSpiner';
export default class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			username: '',
			password: '',
			redirect: 0,
			loading: false,
			user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
		}
	}
	ganti = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	componentDidMount = async () => {
		if (this.state.user) {
			let f = new FormData()
			f.append('token', this.state.user.token)
			await Api.post('auth/cek', f)
				.then((d) => {
					let data = d.data
					if (data.login) {
						this.setState({ redirect: 1 })
					} else {
						this.setState({ redirect: 0 })
					}
				})
				.catch((err) => {
					this.setState({ redirect: 0 })
					console.log(err)
				})

		}
	}
	onSubmit = async (e) => {
		e.preventDefault()
		this.setState({ loading: true })
		let f = new FormData();
		f.append('username', this.state.username)
		f.append('password', this.state.password)
		await Api.post('auth/login', f)
			.then((d) => {
				let data = d.data
				if (data.login) {
					this.setState({ redirect: 1, loading: false }, () => localStorage.setItem('user', JSON.stringify(data)))
					Notif('s', 'hai.. ' + this.state.username + ' Selamat Datang')
				} else {
					this.setState({ loading: false })
					Notif('w', 'hai.. ' + this.state.username + ' Username/Password Salah')
				}
			})
			.catch((err) => {
				this.setState({ loading: false })
				Notif('e', 'hai.. ' + this.state.username + ' Ada Kesalahan')
				console.log(err)
			})
	}
	render() {
		if (this.state.redirect === 1) {
			return <Redirect to="/pengelolah" />
		}

		return (
			<div>
				<LoadingSpiner loading={this.state.loading} />
				<DemoNavbar />
				<main ref="main">

					<section className="section section-shaped section-lg">
						<div className="shape shape-style-1 bg-gradient-default">
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<Container className="pt-lg-7">

							<Row className="justify-content-center">
								<Col lg="5">
									<Card className="bg-secondary shadow border-0">
										<CardHeader className="bg-white pb-5">
											<div className="text-muted text-center">
												<span>Login Page</span>
											</div>

										</CardHeader>
										<CardBody className="px-lg-5 py-lg-5">

											<Form role="form" onSubmit={this.onSubmit}>
												<FormGroup className="mb-3">
													<InputGroup className="input-group-alternative">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="fa fa-user" />
															</InputGroupText>
														</InputGroupAddon>
														<Input placeholder="Username" type="text" name="username"
															onChange={(e) => this.ganti(e)}
														/>
													</InputGroup>
												</FormGroup>
												<FormGroup>
													<InputGroup className="input-group-alternative">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="ni ni-lock-circle-open" />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Password"
															type="password"
															autoComplete="off"
															name="password"
															onChange={(e) => this.ganti(e)}
														/>
													</InputGroup>
												</FormGroup>
												<div className="custom-control custom-control-alternative custom-checkbox">
													<input
														className="custom-control-input"
														id=" customCheckLogin"
														type="checkbox"
													/>
													<label
														className="custom-control-label"
														htmlFor=" customCheckLogin"
													>
														<span>Remember me</span>
													</label>
												</div>
												<div className="text-center">
													<Button
														className="my-4"
														color="primary"
														type="submit"
													>
														Login
													</Button>
												</div>
											</Form>
										</CardBody>
									</Card>
									<Row className="mt-3">
										<Col xs="6">
											<a
												className="text-light"
												href="#pablo"
												onClick={e => e.preventDefault()}
											>
												<small>Forgot password?</small>
											</a>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
				</main>
				<ToastContainer />
				<SimpleFooter />
			</div>
		);
	}
}
